/** =================== MONTSERRAT =================== **/

/** Montserrat Thin **/
@font-face {
    font-weight: 100;
    font-family: Montserrat;
    font-style: normal;
    src: url('./Montserrat-Thin.woff2') format('woff2'),
        url('./Montserrat-Thin.woff') format('woff');
}

/** Montserrat ExtraLight **/

/* @font-face { */

/*    font-weight: 200; */

/*    font-family: Montserrat; */

/*    font-style: normal; */

/*    src: url('./Montserrat-ExtraLight.woff2') format('woff2'), */

/*        url('./Montserrat-ExtraLight.woff') format('woff'); */

/* } */

/** Montserrat Light **/
@font-face {
    font-weight: 300;
    font-family: Montserrat;
    font-style: normal;
    src: url('./Montserrat-Light.woff2') format('woff2'),
        url('./Montserrat-Light.woff') format('woff');
}

/** Montserrat Regular **/
@font-face {
    font-weight: 400;
    font-family: Montserrat;
    font-style: normal;
    src: url('./Montserrat-Regular.woff2') format('woff2'),
        url('./Montserrat-Regular.woff') format('woff');
}

/** Montserrat Medium **/
@font-face {
    font-weight: 500;
    font-family: Montserrat;
    font-style: normal;
    src: url('./Montserrat-Medium.woff2') format('woff2'),
        url('./Montserrat-Medium.woff') format('woff');
}

/** Montserrat SemiBold **/
@font-face {
    font-weight: 600;
    font-family: Montserrat;
    font-style: normal;
    src: url('./Montserrat-SemiBold.woff2') format('woff2'),
        url('./Montserrat-SemiBold.woff') format('woff');
}

/** Montserrat Bold **/
@font-face {
    font-weight: 700;
    font-family: Montserrat;
    font-style: normal;
    src: url('./Montserrat-Bold.woff2') format('woff2'),
        url('./Montserrat-Bold.woff') format('woff');
}

/** Montserrat ExtraBold **/
@font-face {
    font-weight: 800;
    font-family: Montserrat;
    font-style: normal;
    src: url('./Montserrat-ExtraBold.woff2') format('woff2'),
        url('./Montserrat-ExtraBold.woff') format('woff');
}

/* !** Montserrat Black **! */

/* @font-face { */

/*    font-weight: 900; */

/*    font-family: Montserrat; */

/*    font-style: normal; */

/*    src: url('./Montserrat-Black.woff2') format('woff2'), */

/*        url('./Montserrat-Black.woff') format('woff'); */

/* } */
